<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Total Invoice & Price Offer</b-card-title>
      <!-- dropdown -->
      <b-dropdown
        dropright
        variant="link"
        toggle-class="p-0"
        no-caret
      >
        <template #button-content>
          <feather-icon
            class="text-body"
            icon="MoreVerticalIcon"
          />
        </template>
        <b-dropdown-item>
          Last 28 Days
        </b-dropdown-item>
        <b-dropdown-item>
          Last Month
        </b-dropdown-item>
        <b-dropdown-item>
          Last Year
        </b-dropdown-item>
      </b-dropdown>
      <!--/ dropdown -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-polar-area-chart
        v-if="isLoadData"
        :height="350"
        :data="chartjsData.polarChart.data"
        :options="chartjsData.polarChart.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardBody, BCardHeader, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import reportWarehouse from '@/service/warehouse/warehouse-report'
import ChartjsComponentPolarAreaChart from './charts-components/ChartjsComponentPolarAreaChart.vue'
import chartjsData from './chartjsData'

export default {
  components: {
    ChartjsComponentPolarAreaChart,
    BCard,
    BCardTitle,
    BCardBody,
    BCardHeader,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      chartjsData,
      isLoadData: false,
    }
  },
  async created() {
    chartjsData.polarChart.data.datasets[0].data[0] = 100
    chartjsData.polarChart.data.datasets[0].data[1] = 200
    chartjsData.polarChart.data.datasets[0].data[2] = 300
    chartjsData.polarChart.data.datasets[0].data[3] = 400
    this.getApiSelect()
  },
  methods: {
    async getApiSelect() {
      this.showOverLay = true
      await reportWarehouse.reportAxios({
        report_no: 1,
      }).then(response => {
        this.chartjsData.polarChart.data.datasets[0].data[0] = response.data[0].Total
        this.showError = false
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await reportWarehouse.reportAxios({
        report_no: 2,
      }).then(response => {
        this.chartjsData.polarChart.data.datasets[0].data[2] = response.data[0].Total
        this.showError = false
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await reportWarehouse.reportAxios({
        report_no: 3,
      }).then(response => {
        this.chartjsData.polarChart.data.datasets[0].data[1] = response.data[0].Total
        this.showError = false
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await reportWarehouse.reportAxios({
        report_no: 4,
      }).then(response => {
        this.chartjsData.polarChart.data.datasets[0].data[3] = response.data[0].Total
        this.showError = false
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      }).finally(() => {
        this.isLoadData = true
        this.showOverLay = false
      })
    },
  },
}
</script>

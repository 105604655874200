<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Sales Statistics</b-card-title>
      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div>
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-bar-chart
        v-if="isLoadData"
        :height="400"
        :data="chartjsData.latestBarChart.data"
        :options="chartjsData.latestBarChart.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import reportWarehouse from '@/service/warehouse/warehouse-report'
import ChartjsComponentBarChart from './charts-components/ChartjsComponentBarChart.vue'
import chartjsData from './chartjsData'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    flatPickr,
    ChartjsComponentBarChart,
  },
  data() {
    return {
      chartjsData,
      rangePicker: ['2022-11-01', '2022-11-05'],
      isLoadData: false,
    }
  },
  // mounted() {
  //   this.getApiSelect()
  // },
  async created() {
    // chartjsData.polarChart.data.datasets[0].data[0] = 100
    // chartjsData.polarChart.data.datasets[0].data[1] = 200
    // chartjsData.polarChart.data.datasets[0].data[2] = 300
    // chartjsData.polarChart.data.datasets[0].data[3] = 400
    if (!this.isLoadData) {
      this.chartjsData.latestBarChart.data.datasets[0].data = []
      this.chartjsData.latestBarChart.data.labels = []
      this.getApiSelect()
    }
  },
  methods: {
    async getApiSelect() {
      this.showOverLay = true
      await reportWarehouse.reportAxios({
        report_no: 8,
      }).then(response => {
        if (response.data) {
          response.data.forEach(itemVal => {
            this.chartjsData.latestBarChart.data.datasets[0].data.push(itemVal.Total)
            this.chartjsData.latestBarChart.data.labels.push(itemVal.month_year)
          })
          this.isLoadData = true
        }
        this.showError = false
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      }).finally(() => {
        this.showOverLay = false
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<template>
  <b-card
    v-if="statisticsItems"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Statistics</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        Updated 1 month ago
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in statisticsItems"
          :key="item.icon"
          xl="3"
          sm="6"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import reportWarehouse from '@/service/warehouse/warehouse-report'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  // props: {
  //   data: {
  //     type: Array,
  //     default: () => [],
  //   },
  // },
  data() {
    return {
      statisticsItems: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '230k',
          subtitle: 'Total Sales',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'SlackIcon',
          color: 'light-info',
          title: '8.549k',
          subtitle: 'Number Sales',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-danger',
          title: '1.423k',
          subtitle: 'Total Purchases',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'TagIcon',
          color: 'light-success',
          title: '$9745',
          subtitle: 'Number Purchases',
          customClass: '',
        },
      ],
    }
  },
  async created() {
    this.getApiSelect()
  },
  methods: {
    async getApiSelect() {
      this.showOverLay = true
      await reportWarehouse.reportAxios({
        report_no: 1,
        warehouse_id: this.warehouse_id ? this.warehouse_id.id : '',
        item_id: this.item_id ? this.item_id.id : '',
      }).then(response => {
        this.report_data = response.data
        this.statisticsItems[0].title = response.data[0].Total
        this.statisticsItems[1].title = response.data[0].Count
        this.showError = false
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await reportWarehouse.reportAxios({
        report_no: 2,
        warehouse_id: this.warehouse_id ? this.warehouse_id.id : '',
        item_id: this.item_id ? this.item_id.id : '',
      }).then(response => {
        this.report_data = response.data
        this.statisticsItems[2].title = response.data[0].Total
        this.statisticsItems[3].title = response.data[0].Count
        this.showError = false
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      }).finally(() => { this.showOverLay = false })
    },
  },
}
</script>

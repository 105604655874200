<template>
  <b-card title="Number Invoice & Price Offer">
    <!-- chart -->
    <chartjs-component-doughnut-chart
      v-if="isLoadData"
      :height="275"
      :data="chartjsData.doughnutChart.data"
      :options="chartjsData.doughnutChart.options"
      class="mb-3"
    />
    <!--/ chart -->

    <!-- stocks -->
    <div
      v-for="(stock,index) in stockData"
      :key="stock.device"
      :class="index < stockData.length-1 ? 'mb-1':''"
      class="d-flex justify-content-between"
    >
      <div class="d-flex align-items-center">
        <feather-icon
          :icon="stock.symbol"
          size="16"
          :class="stock.color"
        />
        <span class="font-weight-bold ml-75 mr-25">{{ stock.device }}</span>
        <span>- {{ stock.percentage }}</span>
      </div>
      <div>
        <span>{{ stock.upDown }}</span>
        <feather-icon
          :icon="stock.upDown > 0 ? 'ArrowUpIcon':'ArrowDownIcon'"
          :class="stock.upDown > 0 ? 'text-success':'text-danger'"
        />
      </div>
    </div>
    <!--/ stocks -->
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import reportWarehouse from '@/service/warehouse/warehouse-report'
import ChartjsComponentDoughnutChart from './charts-components/ChartjsComponentDoughnutChart.vue'
import chartjsData from './chartjsData'

export default {
  components: {
    ChartjsComponentDoughnutChart,
    BCard,
  },
  data() {
    return {
      chartjsData,
      isLoadData: false,
      stockData: [
        {
          device: 'Sales Invoice', symbol: 'TabletIcon', color: 'text-primary', percentage: 50, upDown: 2,
        },
        {
          device: 'Sales Price Offer', symbol: 'TabletIcon', color: 'text-warning', percentage: 10, upDown: 8,
        },
        {
          device: 'Purchases Invoice', symbol: 'TabletIcon', color: 'text-success', percentage: 10, upDown: -5,
        },
        {
          device: 'Purchases Price Offer', symbol: 'TabletIcon', color: 'text-info', percentage: 30, upDown: -1,
        },
      ],
    }
  },
  async created() {
    // chartjsData.polarChart.data.datasets[0].data[0] = 100
    // chartjsData.polarChart.data.datasets[0].data[1] = 200
    // chartjsData.polarChart.data.datasets[0].data[2] = 300
    // chartjsData.polarChart.data.datasets[0].data[3] = 400
    this.getApiSelect()
  },
  methods: {
    async getApiSelect() {
      this.showOverLay = true
      await reportWarehouse.reportAxios({
        report_no: 1,
      }).then(response => {
        this.chartjsData.doughnutChart.data.datasets[0].data[0] = response.data[0].Count
        this.stockData[0].percentage = response.data[0].Count
        this.showError = false
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await reportWarehouse.reportAxios({
        report_no: 2,
      }).then(response => {
        this.chartjsData.doughnutChart.data.datasets[0].data[2] = response.data[0].Count
        this.stockData[2].percentage = response.data[0].Count
        this.showError = false
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await reportWarehouse.reportAxios({
        report_no: 3,
      }).then(response => {
        this.chartjsData.doughnutChart.data.datasets[0].data[1] = response.data[0].Count
        this.stockData[1].percentage = response.data[0].Count
        this.showError = false
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await reportWarehouse.reportAxios({
        report_no: 4,
      }).then(response => {
        this.chartjsData.doughnutChart.data.datasets[0].data[3] = response.data[0].Count
        this.stockData[3].percentage = response.data[0].Count
        this.showError = false
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      }).finally(() => {
        this.isLoadData = true
        this.showOverLay = false
      })
    },
  },
}
</script>

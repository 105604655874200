<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <!-- <b-col
        xl="4"
        md="6"
      >
        <ecommerce-medal :data="data.congratulations" />
      </b-col> -->
      <b-col
        xl="12"
        md="12"
      >
        <ecommerce-statistics />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col md="6">
        <chartjs-polar-area-chart />
      </b-col>
      <b-col md="6">
        <chartjs-doughnut-chart />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col md="12">
        <chartjs-bar-chart />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

// import { getUserData } from '@/auth/utils'
import ChartjsPolarAreaChart from '@/views/charts-and-maps/charts/chartjs/ChartjsPolarAreaChart.vue'
import ChartjsDoughnutChart from '@/views/charts-and-maps/charts/chartjs/ChartjsDoughnutChart.vue'
import ChartjsBarChart from '@/views/charts-and-maps/charts/chartjs/ChartjsBarChart.vue'
import EcommerceStatistics from './EcommerceStatistics.vue'

export default {
  components: {
    BRow,
    BCol,
    EcommerceStatistics,
    ChartjsPolarAreaChart,
    ChartjsDoughnutChart,
    ChartjsBarChart,
  },
  // data() {
  //   return {
  //     data: {},
  //   }
  // },
  // created() {
  //   // data
  //   this.$http.get('/ecommerce/data')
  //     .then(response => {
  //       this.data = response.data

  //       // ? Your API will return name of logged in user or you might just directly get name of logged in user
  //       // ? This is just for demo purpose
  //       const userData = getUserData()
  //       this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
  //     })
  // },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
